<template>
  <div class="popover-wrapper" v-show="visible">
    <div class="popover-content InterExtraBold largeText">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopOver",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.popover-wrapper {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.popover-content {
  background-color: white;
}
</style>